<footer>
  <section class="home-footer-top">
    <div class="container">
      <div class="footer-container">
        <div class="footer-box about-us">
          <h4 class="footer-box-title"><b>About Us</b></h4>
          <p>Track your habits alongside your friends and stay accountable.
            Self-improvement, together.</p>
        </div>
        <div class="footer-box contact">
          <h4 class="footer-box-title"><b>Contact</b></h4>
          <a href="mailto:notionhabitheroes@gmx.net">
            <ul class="contact-list">
              <li>
                <span class="icon email-icon"></span>
                <div style="font-size: 16px">
                  <strong>Email</strong><br>
                  notionhabitheroes&#64;gmx.net
                </div>
              </li>
            </ul>
          </a>
        </div>
        <div class="footer-box disclaimer">
          <h4 class="footer-box-title"><b>Disclaimer</b></h4>
          <a style="font-size: 16px" href="/terms" class="footer-link">Terms of Use</a>
          <a style="font-size: 16px" href="/privacy" class="footer-link">Privacy Policy</a>
          <p>© 2025 NotionHabitHeroes</p>
        </div>
      </div>
    </div>
  </section>
</footer>
