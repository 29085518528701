<header id="home-header" [ngClass]="{'header-fixed': isFixed}">
  <div class="container">
    <div class="header-mobile-top">
      <div class="home-logo mr-1">
          <a routerLink="/">

            <img
              ngSrc="assets/images/logo_with_name_351x84.webp"
              alt="Notion Habit Heroes Logo"
              width="351"
              height="84"
              loading="lazy"
              fetchpriority="auto"
              srcset="
    assets/images/logo_with_name_117x28.webp 117w,
    assets/images/logo_with_name_351x84.webp 351w,
    assets/images/logo_with_name_700x168.webp 700w,
    assets/images/logo_with_name_1269x303.webp 1269w
  "
              sizes="
    (max-width: 600px) 117px,
    (max-width: 1200px) 351px,
    700px
    "
            />


          </a>
      </div>
    </div>
    <div fxFlex class="top-menu">
<!--      <button mat-button id="dashboard-topbtn3" class="hometop-btn" scrollTo="service-section"><strong>Services</strong></button>-->
<!--      <button mat-button id="dashboard-topbtn4" class="hometop-btn" scrollTo="testimonials-section"><strong>Testimonials</strong></button>-->
<!--      <button mat-button id="dashboard-topbtn5" class="hometop-btn" scrollTo="pricings-section"><strong>Pricings</strong></button>-->
      <button *ngIf="loggedIn" mat-button id="dashboard-topbtn5" class="hometop-btn" routerLink="/start"><strong>Get Started</strong></button>
      <button *ngIf="loggedIn" mat-button id="dashboard-topbtn6" class="hometop-btn" routerLink="/competition"><strong>Competitions</strong></button>
<!--      <button mat-button id="dashboard-topbtn6" class="hometop-btn" routerLink="/contact"><strong>Contact</strong></button>-->

      <span fxFlex></span>
      <div class="icon-container">
        <ng-container *ngIf="loggedIn; else notLoggedIn">
          <button mat-button id="dashboard-topbtn9" class="hometop-btn icon-button" routerLink="/profile"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor" style="transform: translateY(0px);">
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
          </svg><strong>{{this.loggedInEmail}}</strong></button>
          <button mat-button id="dashboard-topbtn10" class="hometop-btn" (click)="logout()"><strong>Logout</strong></button>
        </ng-container>
        <ng-template #notLoggedIn>
          <button mat-button id="dashboard-topbtn11" class="hometop-btn icon-button" routerLink="/login"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" fill="currentColor" class="icon" style="transform: translateY(1px);">
            <path d="M12 12c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm0 2c-3.33 0-10 1.67-10 5v2h20v-2c0-3.33-6.67-5-10-5z"/>
          </svg><strong> My Account</strong></button>
        </ng-template>
      </div>
    </div>
  </div>
</header>

